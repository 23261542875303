export function KickAllIcon({ style }: { style?: React.CSSProperties }) {
    return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M18.97 18.35a3.076 3.076 0 0 1-3.42 3.4h-7.1a4.075 4.075 0 0 1-1.529-.25.75.75 0 1 1 .558-1.4 2.679 2.679 0 0 0 .971.146h7.1c1.363 0 1.92-.55 1.92-1.9a3.42 3.42 0 0 0-3.4-3.7.748.748 0 0 1-.714-.783.768.768 0 0 1 .783-.715 4.872 4.872 0 0 1 4.831 5.202zm-12.67.407L2.53 22.53a.75.75 0 0 1-1.06-1.06l3.579-3.579a4.81 4.81 0 0 1 4.721-4.722l1.486-1.485a3.755 3.755 0 1 1 4.428-4.428l2.95-2.95a1.407 1.407 0 0 0-1.124-.556 1.345 1.345 0 0 0-.947.367.75.75 0 1 1-1.046-1.074 2.832 2.832 0 0 1 1.993-.793 2.9 2.9 0 0 1 2.184 1l1.776-1.78a.75.75 0 0 1 1.06 1.06L6.3 18.756zm6.55-8.667 1.24-1.24a2.29 2.29 0 0 0 .17-.84 2.255 2.255 0 1 0-2.25 2.25 2.29 2.29 0 0 0 .84-.17zm5.94-1.41h-.22a.75.75 0 0 0 0 1.5h.22a2.261 2.261 0 0 1 2.46 2.47c0 .864-.309 1.17-1.18 1.17h-.36a.75.75 0 0 0 0 1.5h.36a2.435 2.435 0 0 0 2.68-2.67 3.76 3.76 0 0 0-3.96-3.97zM5.922 8.035a.75.75 0 1 0 .3-1.471A1.421 1.421 0 0 1 6.51 3.75a1.386 1.386 0 0 1 .956.375.749.749 0 0 0 1.029-1.09A2.875 2.875 0 0 0 6.51 2.25a2.922 2.922 0 0 0-.588 5.785zM3.93 15.32h.35a.75.75 0 1 0 0-1.5h-.35c-.871 0-1.18-.306-1.18-1.17a2.261 2.261 0 0 1 2.46-2.47h.99a.75.75 0 0 0 0-1.5h-.99a3.726 3.726 0 0 0-3.96 3.97 2.435 2.435 0 0 0 2.68 2.67z" />
    </svg>;
}

export function KickOneIcon({ style }: { style?: React.CSSProperties }) {
    return <svg style={style} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M19.75 18.02c0 2.405-1.332 3.73-3.75 3.73H8a4.932 4.932 0 0 1-1.072-.108.75.75 0 1 1 .324-1.464A3.555 3.555 0 0 0 8 20.25h8c1.577 0 2.25-.667 2.25-2.23a3.954 3.954 0 0 0-3.411-4.22.75.75 0 1 1 .2-1.486 5.422 5.422 0 0 1 4.711 5.706zm1.78-14.49-19 19a.75.75 0 0 1-1.06-1.06l2.842-2.843a4.87 4.87 0 0 1-.062-.607c0-2.662 1.506-5.77 5.75-5.77h.689l1.521-1.52c-.068 0-.132.02-.2.02a4.25 4.25 0 1 1 4.25-4.25c0 .069-.017.133-.02.2l4.23-4.23a.75.75 0 0 1 1.06 1.06zm-9.521 5.72a2.75 2.75 0 1 0-2.75-2.75 2.753 2.753 0 0 0 2.75 2.75zm-6.18 7.861 3.293-3.293a3.7 3.7 0 0 0-3.293 3.293z" />
    </svg>;
}
